import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

const content = [
    {
        category: 'Strategic planning',
        items: [  
            {link: "/innovation-center/creating-a-vba-macro-part-1/", image: "/assets/tips-tricks.jpg", title: "Tips and tricks for System Architect", external: false},
            {link: "/innovation-center/system-architect-microsoft-visio-mapper-utility/", image: "/assets/visio.jpg", title: "Microsoft Visio mapper utility for System Architect", external: false},
            {link: "/innovation-center/system-architect-green-it-solution/", image: "/assets/eco-energy.jpg", title: "Green IT add-on for System Architect", external: false},
            {link: "https://www.eaframeworks.com/products", image: "/assets/add-ons.jpg", title: "Reporting, analysis and Visio export add-ons", external: true},
            {link: "/innovation-center/system-architect-tmforum-frameworx/", image: "/assets/business-process.jpg", title: "TM forum models in System Architect", external: false},
            {link: "/innovation-center/system-architect-apqc-process-models/", image: "/assets/apqc.jpg", title: "APQC models in System Architect", external: false},
            {link: "https://www.iet-solutions.com/about/resources/", image: "/assets/manual.png", title: "IT Service Management and ITIL whitepapers by iET Solutions", external: true},
            {link: "https://teamblue.unicomsi.com/products/system-architect#services", image: "/assets/training.jpg", title: "EA training instructor led classes", external: true},
        ]
    },
    {
        category: 'Defense architecture',
        items: [  
            {link: "/innovation-center/creating-a-vba-macro-part-1/", image: "/assets/tips-tricks.jpg", title: "Tips and tricks for System Architect", external: false},
            {link: "/innovation-center/system-architect-microsoft-visio-mapper-utility/", image: "/assets/visio.jpg", title: "Microsoft Visio mapper utility for System Architect", external: false},
            {link: "https://support.unicomsi.com/systemarchitectutilities/System_Architect_Utilities.aspx", image: "/assets/settings.png", title: "DoDAF 1.5 to DoDAF 2 conversion toolkit", external: true},
            {link: "https://www.eaframeworks.com/products", image: "/assets/add-ons.jpg", title: "Reporting, analysis and Visio export add-ons", external: true},
            {link: "https://support.unicomsi.com/systemarchitectutilities/Military_Images/military_images.aspx", image: "/assets/soldier.png", title: "Military images", external: true},
            {link: "https://support.unicomsi.com/systemarchitectutilities/System_Architect_Utilities.aspx", image: "/assets/togaf-workshop.jpg", title: "DoDAF 2.02 free workshop", external: true},
            {link: "https://teamblue.unicomsi.com/products/system-architect#services", image: "/assets/training.jpg", title: "DoDAF 2 instructor led classes", external: true},
        ]
    },
    {
        category: 'Mainframe modernization',
        items: [  
            {link: "https://www.macro4.com/files/2614/8605/0930/How_to_switch_session_manager_painlessly.pdf", image: "/assets/mainframe.png", title: "Modernizing mainframe access: session management guide", external: true},
            {link: "https://www.macro4.com/solutions/performance-management/mainframe-application-performance-management/", image: "/assets/speed.png", title: "Application performance management on the mainframe ", external: true},
            {link: "https://www.macro4.com/solutions/fault-analysis-testing/", image: "/assets/fault.png", title: "Mainframe fault analysis and testing", external: true},
            {link: "https://www.macro4.com/tubes-web-enabled-session-management", image: "/assets/add-ons.jpg", title: "Data management and manipulation for z/OS", external: true},
            {link: "https://www.macro4.com/tubes-web-enabled-session-management", image: "/assets/video.png", title: "View instant web and mobile access to your mainframe applications with Tubes for z/OS", external: true},
            {link: "https://www.macro4.com/products/insync/", image: "/assets/data-security.png", title: "System Z data management and security with InSync", external: true},
        ]
    },
        {
        category: 'Security',
        items: [ 
            {link: "/innovation-center/reference-models-ibm-garage-method/", image: "/assets/system-architect-icon.png", title: "System Architect – Security Architecture in IBM Garage Method template", external: false}, 
            {link: "https://www.unicomgov.com/solutions/capabilities/security/", image: "/assets/government.png", title: "UNICOM Government security solutions", external: true},
            {link: "http://www.unicomengineering.com/industries/information-security/", image: "/assets/hacker.png", title: "UNICOM Engineering hardware security solutions", external: true},
            {link: "https://www.firetide.com/files/8514/4053/8373/mesh_security_whitepaper.pdf", image: "/assets/mesh.png", title: "Firetide mesh video communications and security", external: true},
            {link: "https://www.macro4.com/products/insync/", image: "/assets/data-sec.png", title: "System Z data management and security with InSync", external: true},
            {link: "http://go.unicomengineering.com/Transparent-Supply-Chain.html", image: "/assets/logistics.png", title: "UNICOM Engineering and Intel whitepaper", external: true},
        ]
    },
]

class BlogSectionResources extends React.Component {
  render() {
    // const pathPrefix = this.props.page > 1 ? "../../../../" : "../../../"
    const pathPrefix = ""

    return (
      <section className="bg-light-blue">
        <MDBContainer>
          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left pb-5">Recommended for you</h2>
          <MDBRow>
          {content.filter(content => content.category === this.props.filterId).map((category, index) => {
            return (
              <Fragment key={index}>
                {category.items.map((item, index) => {
                    return (
                      <MDBCol md="3" className="pb-30 d-flex" key={index}>
                        <div className="card flex-fill outline card-hover">
                          <div className="card-body">
                            {item.link.substring(0, 4) != 'http' ? 
                              <Link to={item.link} className="uic-card">
                                <div style={{ minWidth: '85px', maxWidth: '85px' }}>
                                  <img src={pathPrefix + item.image} alt={item.title} loading="lazy" />
                                </div>
                                <div>
                                  <p className="font-alt font-w-700 letter-spacing-1 text-xs-large text-large pt-4">
                                    {item.title} {item.external && <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-2" />}
                                  </p>
                                </div>
                              </Link>
                            : 
                              <a href={item.link} target="_blank" className="uic-card">
                                <div style={{ minWidth: '85px', maxWidth: '85px' }}>
                                  <img src={item.image} alt={item.title} loading="lazy" />
                                </div>
                                <div>
                                  <p className="font-alt font-w-700 letter-spacing-1 text-xs-large text-large pt-4">
                                    {item.title} {item.external && <FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                                  </p>
                                </div>
                              </a>
                            }
                          </div>
                        </div>
                      </MDBCol>
                    )
                })}
              </Fragment>
            )
          })}
          </MDBRow>          
        </MDBContainer>
      </section>      
    )
  }
}
export default BlogSectionResources